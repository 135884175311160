<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning
      v-if="state.hasError"
      :error="state.accessError"
    />

    <div
      v-if="!state.hasError"
      class="flex flex-col sm:flex-row sm:justify-between sm:items-center border-b pb-2"
    >
      <div class="flex justify-between items-center">
        <h2 class="font-bold text-xl sm:text-2xl">
          Machines List
        </h2>
        <add-link
          v-if="machines?.permissions?.can_create"
          :to="{ name: 'SettingsMachinesCreate' }"
          title="Add"
          class="sm:hidden"
        />
      </div>

      <div class="sm:flex sm:flex-row sm:items-center sm:justify-between sm:divide-x sm:space-x-4 mt-3 sm:mt-0">
        <text-input
          v-model="state.search"
          type="search"
          placeholder="Search..."
          class="w-full sm:w-64 lg:w-96"
        />
        <add-link
          v-if="machines?.permissions?.can_create"
          :to="{ name: 'SettingsMachinesCreate' }"
          title="Add"
          class="hidden sm:flex"
        />
      </div>
    </div>

    <div class="bg-concrete rounded-md shadow-sm mt-6 xl:mt-8">
      <div
        v-if="!state.hasError"
        class="flex flex-col"
      >
        <v-table :is-loading="state.isLoading">
          <template #header>
            <v-table-header>Id</v-table-header>
            <v-table-header>Name</v-table-header>
            <v-table-header>Mac</v-table-header>
            <v-table-header>Owner</v-table-header>
            <v-table-header class="text-right">
              Actions
            </v-table-header>
          </template>

          <v-table-row
            v-for="machine in filteredMachines.items"
            :key="machine.location_id"
            class=" hover:bg-gray-50"
          >
            <v-table-col v-html="highlightInString(machine.location_id.toString(), state.search)" />
            <v-table-col>
              <router-link
                v-if="machine.permissions.can_edit"
                :to="{ name: 'SettingsMachinesEdit', params: { id: machine.location_id } }"
                class="font-semibold"
                v-html="highlightInString(machine.name, state.search)"
              />
              <span
                v-else
                v-html="highlightInString(machine.name, state.search)"
              />
            </v-table-col>
            <v-table-col v-html="highlightInString(machine.mac, state.search)" />
            <v-table-col v-html="machine.owner && highlightInString(machine.owner, state.search)" />
            <v-table-col class="text-right space-x-2 flex justify-end items-center">
              <router-link
                v-if="machine.permissions.can_edit_profile"
                :to="{ name: 'SettingsMachinesCommands', params: { machineId: machine.location_id }}"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150 focus:outline-none"
                title="Admin Command"
              >
                <span class="sr-only">Send Command</span>
                <span class="material-icons-outlined">keyboard</span>
              </router-link>
              <button
                v-if="machine.permissions.can_edit_profile"
                type="button"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150 focus:outline-none"
                title="Machine Detail"
                @click="state.isSlideoverOpen = true; state.activeMachineId = machine.location_id"
              >
                <span class="sr-only">view</span>
                <span class="material-icons-outlined">visibility</span>
              </button>
              <router-link
                v-if="machine.permissions.can_edit"
                :to="{ name: 'SettingsMachinesEdit', params: { id: machine.location_id } }"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                title="Edit Machine"
              >
                <span class="sr-only">edit</span>
                <span class="material-icons-outlined">create</span>
              </router-link>
              <router-link
                v-if="machine.permissions.can_edit_profile"
                :to="{ name: 'SettingsMachinesUsers', params: { machineId: machine.location_id } }"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                title="Machine Users"
              >
                <span class="sr-only">users</span>
                <span class="material-icons-outlined">recent_actors</span>
              </router-link>
              <router-link
                v-if="machine.permissions.can_edit_profile"
                :to="{ name: 'Logs', query: { locationid: machine.location_id } }"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                title="Machine Log"
              >
                <span class="sr-only">logs</span>
                <span class="material-icons-outlined">article</span>
              </router-link>
              <router-link
                v-if="machine.permissions.can_edit_profile"
                :to="{ name: 'SettingsMachinesProfileEdit', params: { machineId: machine.location_id } }"
                class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                title="Machine Profile"
              >
                <span class="sr-only">manage profile</span>
                <span class="material-icons-outlined">settings</span>
              </router-link>
              <button
                v-if="machine.permissions.can_delete"
                type="button"
                class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none"
                title="Delete Machine"
                @click="remove(machine)"
              >
                <span class="sr-only">delete</span>
                <span class="material-icons-outlined">delete</span>
              </button>
            </v-table-col>
          </v-table-row>

          <v-table-row v-if="filteredMachines.items.length === 0">
            <v-table-col colspan="5" class="text-center">
              No Machines
            </v-table-col>
          </v-table-row>

          <template #pagination>
            <pagination :paginator="filteredMachines" />
          </template>
        </v-table>
      </div>
    </div>

    <slideover
      v-model:isOpen="state.isSlideoverOpen"
      @close="() => state.isSlideoverOpen = false"
    >
      <machine-show
        v-if="state.activeMachineId"
        :machine-id="state.activeMachineId"
      />
    </slideover>
  </layout>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import http from '@/services/http.js'
import { Paginator } from '@/services/paginator.js'
import { highlightInString } from '@/helpers/utils.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Default.vue'
import SettingsNav from '@/components/settings/Nav'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import TextInput from '@/components/form/Text.vue'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'
import Slideover from '@/components/Slideover.vue'
import AddLink from '@/components/buttons/AddLink.vue'
import MachineShow from '@/views/machines/Show.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'SettingsMachinesIndex',

  components: {
    Pagination,
    Layout,
    AlertResponseWarning,
    TextInput,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    SettingsNav,
    Slideover,
    MachineShow,
    AddLink,
  },

  setup() {
    const searchQueryLSKey = 'settings_machines_index__search_query'
    const paginator = new Paginator().setPerPage(8)
    const machines = ref([])
    const state = reactive({
      hasError: false,
      accessError: null,
      isLoading: true,
      search: localStorage.getItem(searchQueryLSKey) || '',
      activeMachineId: null,
      isSlideoverOpen: false,
    })

    const getMachines = async () => {
      state.isLoading = true

      try {
        const { data } = await http.get('machine')
        data.locations.sort((a, b) => a.name.localeCompare(b.name))
        machines.value = data
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }


      state.isLoading = false
    }

    const remove = async (machine) => {
      const machineId = parseInt(machine.location_id)

      if (! machines.value.locations.find((machine) => machine.location_id === machineId)?.permissions?.can_delete) {
        return alert('You are not allowed to delete machine!')
      }

      if (window.confirm(`Do you really want delete machine ${machine.name} with ID ${machine.location_id}?`)) {
        try {
          await http.delete(`machine/${machineId}`)
          const index = machines.value.locations.findIndex((machine) => machine.location_id === machineId)
          machines.value.locations.splice(index, 1)
          notification.success('Success', `Machine with ID <b>${machineId}</b> has been deleted`)
        } catch (e) {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }
    }

    const filteredMachines = computed(() => {
      let items = machines.value.locations

      if (state.search !== '') {
        localStorage.setItem(searchQueryLSKey, state.search)
        items =  machines.value.locations?.filter((machine) => {
          return machine.name.toLowerCase().includes(state.search.toLowerCase())
            || machine.location_id.toString().includes(state.search)
            || machine.mac.toString().includes(state.search)
            || machine.owner?.toLowerCase().includes(state.search.toLowerCase())
        })
      } else {
        localStorage.removeItem(searchQueryLSKey)
      }

      return paginator.setItems(items)
    })

    getMachines()

    return {
      machines,
      filteredMachines,
      state,
      remove,
      highlightInString,
    }
  }
}
</script>
